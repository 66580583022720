export const API = {
  LOGIN: "/authenticate",
  REGISTER: "/register",
  VERIFY: "/verify",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CHANGE_PASSWORD: "/change-password",
  ME: "/me",
  CONFIG: "/config",
  ACCOUNT_SETTINGS: "/account-settings",

  PROJECTS: "/projects",
  PROJECTS_SEARCH: "/projects/search",
  PROJECT_CREATE_BY_NAME: "/projects/createByName",
  PROJECT_CREATE: "/projects/create",
  PROJECT_DETAIL: "/projects/:id",
  PROJECT_VIEW_BY_CODE: "/projects/viewByCode/:accessCode",
  PROJECT_DELIVERY_COSTS: "/projects/:id/mapDeliveryCosts",
  PROJECT_UPDATE_DELIVERY_OPTION: "/projects/:id/updateDeliveryOption",
  PROJECT_CHECK_OUT: "/projects/:id/checkout",
  PROJECT_OFFER: "/projects/:id/offerProject",
  PROJECT_REQUEST_OFFER: "/projects/:id/requestOffer",
  PROJECT_UPDATE_ADDRESSES_CHECKOUT: "/projects/updateAddressesCheckout",
  PROJECT_ADAPT_PART_PRICE: "/projects/adaptPartPrice",
  PROJECT_ADAPT_DELIVERY_DATE: "/projects/adaptDeliveryDate",
  PROJECT_SEND_OFFER: "/projects/:id/checkOffer",
  PROJECT_APPROVE_OFFER: "/projects/:id/approve",
  PROJECT_REJECT_OFFER: "/projects/:id/reject",
  PROJECT_APPROVE_OFFER_BY_ACCESS_CODE: "/projects/approveByCode/:accessCode",
  PROJECT_REJECT_OFFER_BY_ACCESS_CODE: "/projects/rejectByCode/:accessCode",
  PROJECT_MARK_AS_READ: "/projects/:id/markAsRead",
  PROJECT_PRICE_BY_DELIVERY_OPTION: "/projects/:id/getPriceByShippingOption",
  PROJECTS_COUNT_UNREAD: "/projects/countUnread",
  PROJECT_RESET_ADAPT: "/projects/:id/resetOriginal",
  PROJECT_FINALIZE_ORDER: "/projects/:id/finalizeOrder",
  PROJECT_DOWNLOAD_ORDER: "/projects/:id/downloadOrder",
  PROJECT_UPDATE_NAME: "/projects/:id/update-name",
  PROJECT_UPDATE_STATUS_ARCHIVE: "/projects/:id/archive",
  PROJECT_UPDATE_STATUS_UNARCHIVE: "/projects/:id/unarchive",
  PROJECT_DELETE: "/projects/:id/delete",

  MATERIALS: "/materials",
  MATERIAL_DELETE: "/materials/:id",
  MATERIALS_SEARCH: "/materials/search",
  MATERIAL_DETAIL: "/materials/:id",
  MATERIAL_GROUPS: "/material-groups",
  MATERIAL_GROUPS_BY_SHOPTYPE: "/material-groups/findByShopType",
  PART_MATERIAL_GROUPS: "/material-groups/part-material-groups",
  MATERIAL_GROUPS_BY_ID: "/material-groups/:id",
  MATERIAL_IMPORT: "/materials/import",
  MATERIAL_EXPORT: "/materials/export",

  MATERIAL_ADD_FAVOURITE: "/users/addFavouriteMaterial/:id",
  MATERIAL_REMOVE_FAVOURITE: "/users/removeFavouriteMaterial/:id",

  RAW_MATERIALS: "/rawMaterial",
  RAW_MATERIALS_BY_ID: "/rawMaterial/:id",
  RAW_MATERIALS_BY_MATERIAL: "/rawMaterial/findByMaterialId/:id",
  SURFACE_TREATMENTS: "/surface-treatments",
  SURFACE_TREATMENTS_BY_ID: "/surface-treatments/:id",
  MACHINE_SETTINGS: "/machine-settings",
  QUICK_SETTINGS: "/quick-settings",
  QUICK_SETTINGS_BY_ID: "/quick-settings/:id",
  SHEET_METAL_MACHINE_SETTINGS: "/machine-settings/sheet-metal",
  SHEET_METAL_CUTTING_MACHINE_SETTINGS: "/machine-settings/sheet-metal/cutting-machine",
  SHEET_METAL_BENDING_MACHINE_SETTINGS: "/machine-settings/sheet-metal/bending-machine",
  SHEET_METAL_DEBURRING_MACHINE_SETTINGS: "/machine-settings/sheet-metal/deburring-machine",
  SHEET_METAL_THREAD_COUNTER_SINK_MACHINE_SETTINGS: "/machine-settings/sheet-metal/thread-counter-sink-machine",
  SHEET_METAL_TROVALIZE_MACHINE_SETTINGS: "/machine-settings/sheet-metal/trovalize-machine",
  SHEET_METAL_COUNTER_SINK_MACHINE_SETTINGS: "/machine-settings/sheet-metal/counter-sink-machine",

  CUSTOMER_OVERVIEW: "/customer-overview",
  CUSTOMER_OVERVIEW_BY_ID: "/customer-overview/:id",
  USER_BY_ID: "/users/:id",
  USERS_BY_EMAIL: "/users/search",
  USER_CHANGE_LANGUAGE: "/users/change-language",
  USER_MANUAL_VERIFY: "/users/manual-verify/:id",

  PART: "/parts/:id",
  PART_UPLOAD: "/parts/upload",
  PART_UPLOAD_TO_PREVIEW: "/parts/preview-image",
  PART_UPDATE_PARAMETERS: "/parts/updateParameters",
  PART_UPDATE_MILLING_PARAMETERS: "/parts/updateMillingParameters",
  PART_UPDATE_SHEETMETAL_STP_PARAMETERS: "/parts/updateSheetMetalStpParameters",
  PART_UPDATE_SHEETMETAL_DXF_PARAMETERS: "/parts/updateSheetMetalDxfParameters",
  PART_UPDATE_SHEETMETAL_DWG_PARAMETERS: "/parts/updateSheetMetalDwgParameters",
  PART_UPDATE_NAME: "/parts/:id/update-name",
  PART_PREVIEW_PRICES: "/parts/previewPrices",
  PART_SHEET_METAL_PREVIEW_PRICES: "/parts/preview-sheet-metal-prices",
  PART_CHECK_DESIGN_PRINCIPLE: "/parts/:id/check-design-principle",

  // PART_UPDATE_PARAMETERS: "/parts/updateParameters",

  PART_UPLOAD_TECHDRAWING_FILE: "/parts/uploadTechDrawingFile",
  PART_REQUEST_TECH_DRAWING_DATA: "/parts/requestTechDrawingData",
  PART_CALCULATE: "/parts/:id/calculate",
  PART_REFERENCE_COSTS: "/parts/:id/referenceCosts",
  PART_UPDATE_REFERENCE_COSTS: "/parts/:id/updateQuantityRefs",

  PART_UPDATE_QUANTITY: "/parts/:id/updateQuantity",
  PART_MINI_REPORT: "/parts/:id/miniReport",
  PART_PROCESSING_REPORT: "/parts/:id/processReport",
  PART_3D_VIEWER: "/parts/3dViewer",
  PART_3D_MODEL: "/parts/:id/s3dModel",
  PART_COPY: "/parts/copy",
  PART_MOVE: "/parts/move",
  PART_TOGGLE_APPROVE: "/projects/toggleApprovePart",
  PART_TOGGLE_APPROVE_BY_ACCESS_CODE: "/projects/toggleApprovePart/:accessCode",
  PART_COST_REPORT: "/parts/:id/costReport",
  PART_EMISSION_REPORT: "/parts/:id/emissionReport",
  PART_DXF_CUSTOM_POST:
    "/parts/analyze-custom-sheet-metal/:projectId/:shapeType",
  PART_DOWNLOAD_CAD_FILE: "/parts/:id/downloadFile",
  PART_DOWNLOAD_ALL_LAYERS_DXF: "/parts/:id/downloadAllLayerDxf ",
  PART_PREVIEW_ADAPTED: "/parts/:id/preview-adapted-part-price",
  PART_UPDATE_TIME_AND_COSTS_ADAPT: "/parts/:id/adapt-part-price",

  ADDRESS: "/addresses",
  DASHBOARD: "/dashboard",

  COUNTRIES: "/countries.json",

  THICKNESS: "/thickness",
  THICKNESS_BY_ID: "/thickness/:id",
  THICKNESS_FIND_BY_MATERIAL_ID: "/thickness/findByMaterialId/:id",
  THICKNESS_FIND_ALL_VALUES: "/thickness/findAllValues",

  PAYMENT_CREATE_SESSION: "payments/:id/create-session",
  PROJECT_MARK_AS_PAID: "projects/:id/markAsPaid",

  COMPLIANCE: "/compliance",
  COMPLIANCE_BY_ID: "/compliance/:id"

};
