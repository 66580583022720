import {
  Button,
  Empty,
  Form,
  Input,
  InputNumber,
  notification,
  Select,
  Switch,
} from "antd";
import CadFileItem from "app/components/Part/CadFileItem";
import FadeIn from "app/components/Shape/FadeIn";
import { CuttingType } from "app/models/CuttingType";
import { Part } from "app/models/Part";
import { PartViewMode } from "app/models/PartViewMode";
import { ProjectStatus } from "app/models/Project";
import { ShopType } from "app/models/ShopType";
import { TableFilter } from "app/models/Table";
import { WeekDayUnit } from "app/models/WeekDayUnit";
import { configSelector } from "app/redux/slides/config.slide";
import partServices from "app/services/part.service";
import projectServices from "app/services/project.service";
import Utils from "app/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { QuickSetting } from "../../models/QuickSetting";
import quickSettingsServices from "../../services/quickSetting.service";
const { Option } = Select;

export function QuickSettingsPage() {
  const { t } = useTranslation();
  const config = useSelector(configSelector);
  const [api, contextHolder] = notification.useNotification();
  const [quickSetting, setQuickSetting] = useState<QuickSetting>();
  const [formQuickSetting] = Form.useForm();
  const [keySelected, setKeySelected] = useState("default");
  const [parts, setParts] = useState<Part[]>([]);
  const [pricePreview, setPricePreview] = useState<any>({});
  const [pricePreviewLoading, setPricePreviewLoading] = useState(false);
  const [deliveryOptionUnit, setDeliveryOptionUnit] = useState(
    WeekDayUnit.WEEK
  );
  const COMMON_FIELD_RULES = [{ required: true }];
  const showMillingFormulaButon =
    config.shopType == ShopType.MILLING_AND_TURNING ||
    config.shopType == ShopType.BOTH;
  const showSheetMetalFormulaButon =
    config.shopType == ShopType.SHEET_METAL || config.shopType == ShopType.BOTH;

  const menus = [
    {
      key: "default",
      label: t("settings.formula.basic"),
    },
    ...(showMillingFormulaButon
      ? [
          {
            key: "formula",
            label: t("settings.formula.formula"),
          },
        ]
      : []),
    ...(showSheetMetalFormulaButon
      ? [
          {
            key: "sheet-metal-formula",
            label: t("settings.formula.sheet-metal"),
          },
        ]
      : []),
  ];

  const getQuickSetting = async () => {
    try {
      const rs = await quickSettingsServices.detail();
      setQuickSetting(rs);
    } catch (error) {}
  };

  const getProjects = async () => {
    try {
      setParts([]);
      const tableFilters: TableFilter = {
        page: 0,
        size: 3,
        selfCalculation: true,
        statuses: [
          ProjectStatus.IN_PROGRESS,
          ProjectStatus.CHECKOUTED,
          ProjectStatus.REQUESTED_OFFER,
          ProjectStatus.PRICE_ADAPTED,
          ProjectStatus.BUYER_APPROVED,
          ProjectStatus.BUYER_REJECTED,
          ProjectStatus.SELLER_APPROVED,
          ProjectStatus.SELLER_REJECTED,
        ].join(","),
      };
      if (keySelected === "formula") {
        tableFilters.partCuttingType = CuttingType.MILLING_AND_TURNING;
      } else {
        tableFilters.partCuttingType = [
          CuttingType.SHEET_METAL_DWG,
          CuttingType.SHEET_METAL_DXF,
          CuttingType.SHEET_METAL_STP,
          CuttingType.SHEET_METAL_CONFIGURE,
        ].join(",");
      }
      const rs = await projectServices.list(tableFilters);
      let parts: Part[] = [];
      let selectCuttingType =
        keySelected === "formula"
          ? [CuttingType.MILLING_AND_TURNING]
          : [
              CuttingType.SHEET_METAL,
              CuttingType.SHEET_METAL_DWG,
              CuttingType.SHEET_METAL_DXF,
              CuttingType.SHEET_METAL_STP,
              CuttingType.SHEET_METAL_CONFIGURE,
            ];
      for (var i = 0; i < rs.data.length; i++) {
        const pr = rs.data[i];

        if (pr.parts.length === 0) break;
        const pt = pr.parts.filter(
          (p: Part) =>
            p.auto &&
            p.cuttingType &&
            selectCuttingType.indexOf(p.cuttingType) != -1 &&
            p.unitPrice
        );

        parts = [...parts, ...pt];
        if (parts.length >= 3) break;
      }
      setParts(parts.length > 3 ? parts.slice(0, 3) : parts);
    } catch (error) {}
  };

  useEffect(() => {
    getQuickSetting();
  }, []);

  useEffect(() => {
    if (keySelected === "formula" || keySelected === "sheet-metal-formula") {
      getProjects();
    }
  }, [keySelected]);

  useEffect(() => {
    if (quickSetting) {
      formQuickSetting.setFieldsValue(quickSetting);
      formQuickSetting.setFieldValue(
        "weekDayUnit",
        quickSetting.weekDayUnit === WeekDayUnit.DAY
      );
      setDeliveryOptionUnit(quickSetting.weekDayUnit);
    }
  }, [quickSetting]);

  const onFinish = async (values: any) => {
    try {
      const data = {
        ...quickSetting,
        ...values,
        weekDayUnit: values.weekDayUnit ? WeekDayUnit.DAY : WeekDayUnit.WEEK,
      };
      await quickSettingsServices.update(data);
      setQuickSetting(data);
      api.success({
        message: `${t("updateSuccess")}!`,
        placement: "topRight",
      });
    } catch (error) {}
  };

  const onMenuSelected = (m: any) => {
    setKeySelected(m.key);
  };

  const onPreviewPrices = async () => {
    setPricePreviewLoading(true);
    try {
      const formulaData = formQuickSetting.getFieldsValue();
      const partIds = parts.map((p) => p.id);

      let rs = {};
      if (keySelected == "sheet-metal-formula") {
        rs = await partServices.previewSheetMetalPrices({
          partIds,
          formulaData,
        });
      } else {
        rs = await partServices.previewPrices({
          partIds,
          formulaData,
        });
      }

      setPricePreview(rs);
    } catch (error) {
      api.error({
        message: `Error!`,
        description: "Preview prices failed!",
        placement: "topRight",
      });
    }

    setPricePreviewLoading(false);
  };

  const onDeliveryOptionUnitChanged = (value: boolean) => {
    setDeliveryOptionUnit(value ? WeekDayUnit.DAY : WeekDayUnit.WEEK);
  };
  const getDeliveryOptionUnit = () => {
    return (
      deliveryOptionUnit === WeekDayUnit.WEEK ? t("weeks") : t("days")
    ).toLowerCase();
  };

  return (
    <>
      {contextHolder}
      <h3>{t("menu.quickSetting")}</h3>
      <div className="b-settings">
        <div className="row">
          <div className="col col-12 col-lg d-lg-flex flex-lg-column gap-3 b-menus">
            {menus.map((m) => (
              <Button
                key={`m-${m.key}`}
                type={m.key === keySelected ? "primary" : "default"}
                shape="round"
                onClick={onMenuSelected.bind(null, m)}
              >
                {m.label}
              </Button>
            ))}
          </div>
          <div className="col col-12 col-lg-8 col-xl-10">
            {keySelected === "default" && (
              <FadeIn>
                <Form
                  form={formQuickSetting}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  className="mt-3"
                >
                  <div className="row">
                    <h6>{t("settings.deliveryTimeNCostFactor")}</h6>
                    <Form.Item name="id" hidden>
                      <Input />
                    </Form.Item>
                    <div className="col col-12 col-lg-6 col-xl-3">
                      <Form.Item
                        name="weekDayUnit"
                        label="Unit"
                        valuePropName="checked"
                      >
                        <Switch
                          checkedChildren={WeekDayUnit.DAY}
                          unCheckedChildren={WeekDayUnit.WEEK}
                          onChange={onDeliveryOptionUnitChanged}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t("settings.automaticCalculation")}
                        name="deliveryTimeEconomy"
                        extra={t("deliveryOption.ECONOMY")}
                        messageVariables={{ label: "Delivery time economy" }}
                        rules={COMMON_FIELD_RULES}
                      >
                        <InputNumber
                          addonAfter={getDeliveryOptionUnit()}
                          min={0}
                        />
                      </Form.Item>
                      <Form.Item
                        className="hidden-label"
                        label={"Delivery time standard"}
                        name="deliveryTimeStandard"
                        extra={t("deliveryOption.STANDARD")}
                        rules={COMMON_FIELD_RULES}
                      >
                        <InputNumber
                          addonAfter={getDeliveryOptionUnit()}
                          min={0}
                        />
                      </Form.Item>
                      <Form.Item
                        className="hidden-label"
                        label={"Delivery time fast"}
                        name="deliveryTimeFast"
                        extra={t("deliveryOption.FAST")}
                        rules={COMMON_FIELD_RULES}
                      >
                        <InputNumber
                          addonAfter={getDeliveryOptionUnit()}
                          min={0}
                        />
                      </Form.Item>
                    </div>
                    <div className="col col-12 col-lg-6 col-xl-3">
                      <Form.Item
                        label={t("settings.surcharge")}
                        name="surchargeEconomy"
                        extra={t("deliveryOption.ECONOMY")}
                        messageVariables={{ label: "Surcharge economy" }}
                        rules={COMMON_FIELD_RULES}
                      >
                        <InputNumber addonAfter="%" min={0} />
                      </Form.Item>
                      <Form.Item
                        className="hidden-label"
                        label={"Surcharge standard"}
                        name="surchargeStandard"
                        extra={t("deliveryOption.STANDARD")}
                        rules={COMMON_FIELD_RULES}
                      >
                        <InputNumber addonAfter="%" min={0} />
                      </Form.Item>
                      <Form.Item
                        className="hidden-label"
                        label={"Surcharge fast"}
                        name="surchargeFast"
                        extra={t("deliveryOption.FAST")}
                        rules={COMMON_FIELD_RULES}
                      >
                        <InputNumber addonAfter="%" min={0} />
                      </Form.Item>
                    </div>

                    <div className="col col-12 col-xl-6">
                      <div className="row">
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("settings.partPriceManualThreshold")}
                            name="partPriceManualThreshold"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber addonAfter="EUR" min={0} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("settings.projectPriceAutoThreshold")}
                            name="projectPriceAutoThreshold"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber addonAfter="EUR" min={0} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("settings.deliverySurcharge")}
                            name="deliverySurcharge"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              type="number"
                              addonAfter="EUR"
                              min={0}
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("settings.fastDeliverySurcharge")}
                            name="fastDeliverySurcharge"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              type="number"
                              addonAfter="EUR"
                              min={0}
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("settings.marginSurfaceTreat")}
                            name="marginSurfaceTreat"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("settings.automaticCalculation")}
                            name="automaticCalculation"
                            valuePropName="checked"
                          >
                            <Switch
                              checkedChildren={t("automaticCalculation.ON")}
                              unCheckedChildren={t("automaticCalculation.OFF")}
                            />
                          </Form.Item>
                        </div>

                        <div className="col col-12">
                          <Form.Item
                            label={t("settings.maximumCalculation")}
                            name="maximumCalculation"
                            tooltip={t(
                              "settings.maximumCalculation.description"
                            )}
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              min={0}
                              onKeyDown={Utils.triggerTypeNumber}
                            />
                          </Form.Item>
                        </div>
                        {/* <div className="col col-12 col-md-6"></div> */}
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            label={t("settings.expiredDays")}
                            name="expiredDays"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              addonAfter={t("days")}
                              min={0}
                              onKeyDown={Utils.triggerTypeNumber}
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6"></div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            rules={COMMON_FIELD_RULES}
                            label={t("settings.minimumOrderValue")}
                            tooltip={t("settings.minimumOrderValue.desc")}
                            name="minimumOrderValue"
                          >
                            <InputNumber min={0} step={1} addonAfter="EUR" />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6">
                          <Form.Item
                            rules={COMMON_FIELD_RULES}
                            label={t(
                              "settings.minimumOrderValueForFastDelivery"
                            )}
                            // tooltip={t("settings.minimumOrderValue.desc")}
                            name="minimumOrderValueForFastDelivery"
                          >
                            <InputNumber min={0} step={1} addonAfter="EUR" />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3" />
                  <div>
                    <Button type="primary" htmlType="submit">
                      {t("saveChanges")}
                    </Button>
                  </div>
                </Form>
              </FadeIn>
            )}
            {keySelected === "formula" && (
              <FadeIn>
                <Form
                  form={formQuickSetting}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  className="mt-3"
                >
                  <Form.Item name="id" hidden>
                    <Input />
                  </Form.Item>
                  <Form.Item name="marginValue" hidden>
                    <Input />
                  </Form.Item>
                  <div className="row">
                    <h6>{t("settings.formula.formula")}</h6>
                    <div className="col col-12">
                      <div className="row">
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.factorManual")}
                            name="factorManual"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.programmingHourRate")}
                            name="programmingHourRate"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber min={0} step={0.1} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.generalAdminAdditions")}
                            name="generalAdminAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.generalSalesAdditions")}
                            name="generalSalesAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.faultyPartsRiskAdditions")}
                            name="faultyPartsRiskAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.margin")}
                            name="marginValue"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.rawMaterialAdditions")}
                            name="rawMaterialAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.processCostAdditions")}
                            name="processCostAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              className="n-input-w-130"
                              addonAfter="%"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.inBetweenTemperingCost")}
                            name="inBetweenTemperingCost"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber className="n-input-w-130" min={0} />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="mt-3" />
                  <div>
                    <Button
                      onClick={onPreviewPrices}
                      className="mb-3"
                      loading={pricePreviewLoading}
                    >
                      {t("settings.formula.previewPrices")}
                    </Button>
                    <Button className="ms-3" type="primary" htmlType="submit">
                      {t("saveChanges")}
                    </Button>
                    {parts?.length === 0 && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No part to preview"
                      />
                    )}
                    {parts?.map((p) => (
                      <CadFileItem
                        key={`milling-part-${p.id}`}
                        part={p}
                        mode={PartViewMode.READ_ONLY}
                        pricePreview={pricePreview[`${p.id}`]}
                        pricePreviewLoading={pricePreviewLoading}
                      />
                    ))}
                  </div>
                </Form>
              </FadeIn>
            )}
            {keySelected === "sheet-metal-formula" && (
              <FadeIn>
                <Form
                  form={formQuickSetting}
                  layout="vertical"
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  className="mt-3"
                >
                  <div className="row">
                    <h6>{t("settings.formula.sheet-metal")}</h6>
                    <Form.Item name="id" hidden>
                      <Input />
                    </Form.Item>
                    <div className="col col-12">
                      <div className="row">
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.transportAdditions"
                            )}
                            name="sheetMetalTransportAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber min={0} max={1} step={0.1} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.distributionTimeAdditions"
                            )}
                            name="sheetMetalDistributionTimeAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber min={0} step={0.01} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.personalTimeAdditions"
                            )}
                            name="sheetMetalPersonalTimeAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber min={0} max={1} step={0.01} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.technicalDiscussions"
                            )}
                            name="sheetMetalTechnicalDiscussions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber min={0} max={1} step={0.01} />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.generalAdmin"
                            )}
                            name="sheetMetalGeneralAdmin"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              min={0}
                              step={1}
                              addonAfter="%"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.generalSales"
                            )}
                            name="sheetMetalGeneralSales"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              min={0}
                              step={1}
                              addonAfter="%"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t("settings.formula.sheetMetal.margin")}
                            name="sheetMetalMargin"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              min={0}
                              step={1}
                              addonAfter="%"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.materialAdditions"
                            )}
                            name="sheetMetalMaterialAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              min={0}
                              step={1}
                              addonAfter="%"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.manufacturingAddition"
                            )}
                            name="sheetMetalManufacturingAdditions"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              min={0}
                              step={1}
                              addonAfter="%"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.costPerHoleThreshold"
                            )}
                            name="sheetMetalCostPerHoleThreshold"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              addonAfter="mm"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.costPerHoleUnderThreshold"
                            )}
                            name="sheetMetalCostPerHoleUnderThreshold"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              addonAfter="€"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>
                        <div className="col col-12 col-md-6 col-xl-3">
                          <Form.Item
                            label={t(
                              "settings.formula.sheetMetal.costPerHoleOverThreshold"
                            )}
                            name="sheetMetalCostPerHoleOverThreshold"
                            rules={COMMON_FIELD_RULES}
                          >
                            <InputNumber
                              addonAfter="€"
                              className="input-addon-w90"
                            />
                          </Form.Item>
                        </div>

                      </div>
                    </div>
                  </div>
                  <hr className="mt-3" />
                  <div>
                    <Button
                      onClick={onPreviewPrices}
                      className="mb-3"
                      loading={pricePreviewLoading}
                    >
                      {t("settings.formula.previewPrices")}
                    </Button>
                    <Button className="ms-3" type="primary" htmlType="submit">
                      {t("saveChanges")}
                    </Button>
                    {parts?.length === 0 && (
                      <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description="No part to preview"
                      />
                    )}
                    {parts?.map((p) => (
                      <CadFileItem
                        key={`sheet-metal-formula-${p.id}`}
                        part={p}
                        mode={PartViewMode.READ_ONLY}
                        pricePreview={pricePreview[`${p.id}`]}
                        pricePreviewLoading={pricePreviewLoading}
                      />
                    ))}
                  </div>
                </Form>
              </FadeIn>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
