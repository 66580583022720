import {
  ArrowRightOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
  EditOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { Button, Card, Form, Select, UploadProps } from "antd";
import { RcFile } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import Utils from "app/utils";
import { images } from "assets/images";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { CuttingType } from "../../models/CuttingType";
import { configSelector } from "../../redux/slides/config.slide";
import CadFileIcon from "../SVGs/CadFileIcon";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import CustomDxfSheetMetal from "./CustomDxfSheetMetal";
import "./calculation.module.scss";
const { Option } = Select;

interface DefaultProps {
  onChange?: any;
  onCuttingTypeChange?: any;
  onGenerateDxf?: any;
}
const millingExts =
  "STEP, JT, SolidWorks, Solid Edge, Autodesk Inventor, Siemens NX, CATIA V5, Creo Parametric";
const sheetMetalExts = "*.stp; *.step; *.dxf; *.dwg";
const buildCadFiles = (files: RcFile[]) => {
  let fData: any = {};
  files?.map((f) => {
    const file: any = new File([f], f.name.replaceAll(",", "_"));
    file.uid = f.uid;
    const p = Utils.parseFileName(f.name);
    const name = p.name;
    const ext = p.ext;
    switch (ext) {
      case "pdf":
        fData[name] = {
          ...fData[name],
          pdfFile: file,
        };
        break;
      case "step":
      case "stp":
      case "dxf":
      case "dwg":
      case "ipt":
      case "jt":
      case "pvz":
      case "edz":
      case "prt":
      case "asm":
      case "sldasm":
      case "sldprt":
      case "model":
      case "catpart":
      case "catproduct":
      case "3ds":
      case "prj":
      case "par":
      case "psm":
        fData[name] = {
          ...fData[name],
          cadFile: file,
        };
        break;
    }
  });
  const data = Object.keys(fData).map((key) => fData[key]);
  return {
    data: data.filter((f: any) => !!f.cadFile),
    pdfFiles: data.filter((f: any) => !f.cadFile).map((f) => f.pdfFile),
  };
};

function CalculationStep1Plastx(props: DefaultProps) {
  const { onChange, onCuttingTypeChange, onGenerateDxf } = props;
  const { t } = useTranslation();
  const config = useSelector(configSelector);
  const [cuttingType, setCuttingType] = useState(CuttingType.SHEET_METAL);
  const [isCustomDxf, setIsCustomDxf] = useState(false);
  const [cadFiles, setCadFiles] = useState<any[]>([]);
  const [dataFiles, setDataFiles] = useState<any[]>([]);
  const [pdfFiles, setPdfFiles] = useState<any[]>([]);

  const onCuttingTypeChanged = (v: CuttingType) => {
    setCuttingType(v);
    if (onCuttingTypeChange) {
      onCuttingTypeChange(v);
    }
    if (v != CuttingType.SHEET_METAL) {
      setIsCustomDxf(false);
    }
  };
  
  useEffect(() => {
    onCuttingTypeChanged(CuttingType.SHEET_METAL)
  }, []);

  const onNext = () => {
    if (onChange && dataFiles.length > 0) {
      const data = {
        cuttingType,
        files: dataFiles,
      };
      onChange(data);
    }
  };
  const autoNextStep = (dataFiles: any[]) => {
    if (onChange && dataFiles.length > 0) {
      const data = {
        cuttingType,
        files: dataFiles,
      };
      onChange(data);
    }
  };

  useEffect(() => {
    const rs = buildCadFiles(cadFiles);
    setDataFiles([...dataFiles, ...rs.data]);
    setPdfFiles([...pdfFiles, ...rs.pdfFiles]);
    if (
      rs.data.length > 0 &&
      (rs.data.findIndex((f) => !f.pdfFile) < 0 || rs.pdfFiles.length == 0)
    ) {
      autoNextStep(rs.data);
    }
  }, [cadFiles]);

  const uploadProps: UploadProps = {
    className: "cad-upload",
    beforeUpload: (file, fileList) => {
      setCadFiles(fileList);
      return false;
    },
    name: "file",
    multiple: true,
    fileList: [],
    accept: `.stp, .step, .jt, .pvz, .edz, .prt, .asm, .sldasm, .sldprt, .model, .catpart, .catproduct, .3ds, .prj, .par, .asm, .psm, .sldprt, .sldasm, .prt, .pdf, ${
      cuttingType === CuttingType.SHEET_METAL ? " .dxf, .dwg," : ""
    } ${cuttingType === CuttingType.MILLING_AND_TURNING ? " .ipt," : ""}`,
  };

  const selectPDFFile = (index: number) => (value: any) => {
    const iPDF = pdfFiles.findIndex((f) => f.uid === value);
    dataFiles[index].pdfFile = pdfFiles[iPDF];
    setDataFiles([...dataFiles]);
    pdfFiles.splice(iPDF, 1);
    setPdfFiles([...pdfFiles]);
  };

  const onRemovePDF = (index: any) => (value: any) => {
    pdfFiles.push(dataFiles[index].pdfFile);
    setPdfFiles([...pdfFiles]);
    delete dataFiles[index].pdfFile;
    setDataFiles([...dataFiles]);
  };

  return (
    <>
      <Form>
        <div className="mt-4 custom-dxf-btn-group">
          <div className="row justify-content-center">
            <div className="col-4">
              <div className="app-tabs">
                <div className={`l-active active-${isCustomDxf ? 1 : 0}`}></div>
                <Button
                  rootClassName={`${!isCustomDxf && "active"}`}
                  type="text"
                  className="col col-6"
                  onClick={setIsCustomDxf.bind(null, false)}
                >
                  <CloudUploadOutlined /> {t("calculation.uploadFile")}
                </Button>
                <Button
                  rootClassName={`${isCustomDxf && "active"}`}
                  type="text"
                  className="col col-6"
                  onClick={setIsCustomDxf.bind(null, true)}
                >
                  <EditOutlined /> {t("calculation.customDXF")}
                </Button>
              </div>
            </div>
          </div>
        </div>
        {!isCustomDxf && (
          <div className="mt-5">
            {/* <p className="sub-l">{t("calculation.uploadFiles")}</p> */}
            <Dragger {...uploadProps}>
              <p className="ant-upload-drag-icon text-primary">
                <CloudUploadOutlined style={{ fontSize: "8rem" }} />
                {/* <img src={images.uploadFilesIcon} /> */}
              </p>
              <div className="files-desciption d-flex justify-content-center">
                <img src={images.cadMultipleFileIcon} />
                <img src={images.pdfMultipleFileIcon} />
              </div>
              <h6 className="ant-upload-text mb-3">
                {t("calculation.uploadCADFiles")}
              </h6>
              <p className="ant-upload-hint px-3 mb-2">
                {t(`calculation.uploadCADFiles.description`, {
                  extensions:
                    cuttingType === CuttingType.SHEET_METAL
                      ? sheetMetalExts
                      : millingExts,
                })}
              </p>
              <p className="ant-upload-hint px-3">
                {t("calculation.uploadCADFiles.pdf")}
              </p>
            </Dragger>
          </div>
        )}
      </Form>
      {isCustomDxf && <CustomDxfSheetMetal onGenerate={onGenerateDxf} />}
      {!isCustomDxf && dataFiles.length > 0 && (
        <div className="d-flex flex-column align-items-center mt-4">
          {dataFiles.map((f, i) => (
            <div key={`cad-${i}`} className="file-mapping row">
              <div className="col-5 file-cad d-flex align-items-center gap-2">
                <CadFileIcon />
                <label className="file-name">{f.cadFile.name}</label>
              </div>
              <div className="col d-flex align-items-center">
                <LinkOutlined />
              </div>
              <div className="col-6 file-pdf d-flex align-items-center gap-2">
                {f.pdfFile && (
                  <>
                    <PdfFileIcon />
                    <label className="file-name">{f.pdfFile.name}</label>
                    <Button
                      type="text"
                      className="ms-auto"
                      onClick={onRemovePDF(i)}
                    >
                      <CloseCircleOutlined className="text-danger" />
                    </Button>
                  </>
                )}
                {!f.pdfFile && (
                  <Select
                    style={{ width: "100%" }}
                    onChange={selectPDFFile(i)}
                    placeholder={t("pleaseSelect")}
                  >
                    {pdfFiles.map((f) => (
                      <Option key={`pdf-${f.uid}`} value={f.uid}>
                        {f.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </div>
            </div>
          ))}
          <Button type="primary" onClick={onNext}>
            {t("calculation.nextStep")} <ArrowRightOutlined />
          </Button>
        </div>
      )}
    </>
  );
}

export default CalculationStep1Plastx;
