import CalculationStep1 from "./CalculationStep1";
import CalculationStep1Plastx from "./CalculationStep1Plastx";

interface DefaultProps {
  onChange: any;
  onCuttingTypeChange: any;
  onGenerateDxf: any;
}

export default function CalculationStep1Switcher(props: DefaultProps) {
  const { onChange, onCuttingTypeChange, onGenerateDxf } = props;

  return (
    <>
      {process.env.REACT_APP_TECHNICAL_UPLOAD_PLASTICS === "true" && (
        <CalculationStep1Plastx
          onChange={onChange}
          onCuttingTypeChange={onCuttingTypeChange}
          onGenerateDxf={onGenerateDxf}
        />
      )}
      {!process.env.REACT_APP_TECHNICAL_UPLOAD_PLASTICS && (
        <CalculationStep1
          onChange={onChange}
          onCuttingTypeChange={onCuttingTypeChange}
          onGenerateDxf={onGenerateDxf}
        />
      )}
    </>
  );
}
