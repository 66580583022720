/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArrowRightOutlined,
  PlusOutlined,
  SettingOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Space,
  notification,
} from "antd";
import { RangePickerProps } from "antd/es/date-picker";
import { materialActions } from "app/redux/slides/material.slide";
import complianceServices from "app/services/compliance.service";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CuttingType } from "../../models/CuttingType";
import { Part } from "../../models/Part";
import { PartViewMode } from "../../models/PartViewMode";
import { Project } from "../../models/Project";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import projectServices from "../../services/project.service";
import CadFileItem from "./CadFileItem";
import DragDropZone from "./DragDropZone";
import "./calculation.module.scss";
import "./calculationStep2.module.scss";
import Sticky from "react-stickynode";
import CalculationStep1Switcher from "./CalculationStep1Switcher";

const MAX_PART = 20;
interface DefaultProps {
  cuttingType: CuttingType;
  files: any[];
  project: Project;
  hidden?: boolean;
  onFilesLoaded?: any;
  onAddFiles?: any;
  onNext?: any;
  onGenerateDxf?: any;
}

// eslint-disable-next-line arrow-body-style
const disabledDate: RangePickerProps["disabledDate"] = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().endOf("day");
};

function CalculationStep2(props: DefaultProps) {
  const [api, contextHolder] = notification.useNotification();
  const dispatch = useDispatch();
  const {
    cuttingType,
    files,
    onFilesLoaded,
    onAddFiles,
    onNext,
    onGenerateDxf,
  } = props;
  const opening = useSelector(projectSelector);
  const project: Project = opening.data;
  const partsStatus = opening.partsStatus;
  const uploadFiles = opening.uploadFiles;
  const { t } = useTranslation();
  const [adaptForm] = Form.useForm();
  const [partsUploaded, setPartsUploaded] = useState<Part[]>([]);
  const [cadFiles, setCadFiles] = useState<any[]>([]);
  const [additionFiles, setAdditionFiles] = useState<any[]>([]);
  const [parts, setParts] = useState<Part[]>(project?.parts || []);
  const [error, setError] = useState<any>();
  const [errorMax, setErrorMax] = useState(false);
  const [isOpepUploadParts, setIsOpepUploadParts] = useState(false);
  const [cuttingTypeUpload, setCuttingTypeUpload] = useState(cuttingType);
  const [isOpenOriginalDeliveryDate, setIsOpenOriginalDeliveryDate] =
    useState(false);
  const [adaptDeliveryDate, setAdaptDeliveryDate] = useState(
    project.order.adaptedDeliveryDate || project.order.deliveryDate
  );
  const [loadingAdaptDeliveryDate, setLoadingAdaptDeliveryDate] =
    useState(false);
  const [isOpenMoveCloneParts, setIsOpenMoveCloneParts] = useState(false);
  const [isSheetMetalPartExsit, setIsSheetMetalPartExsit] = useState(false);
  const [isShowCalculateAll, setIsShowCalculateAll] = useState(
    Object.keys(partsStatus).some(
      (p: any) => partsStatus[p] === PartViewMode.EDIT
    )
  );
  const [partFiles, setPartFiles] = useState<any>();
  const sheetMetalTypes: CuttingType[] = [
    CuttingType.SHEET_METAL,
    CuttingType.SHEET_METAL_DWG,
    CuttingType.SHEET_METAL_STP,
    CuttingType.SHEET_METAL_DXF,
    CuttingType.SHEET_METAL_CONFIGURE,
  ];

  const getCompliances = async () => {
    try {
      const rs = await complianceServices.list({
        size: 10000,
      });
      dispatch(materialActions.setCompliances(rs.data));
    } catch (error) {}
  };

  const onPartUploadedChange = (part: Part) => {
    if (!part) return;
    const ip = partsUploaded.findIndex((p) => p.id === part.id);
    if (ip > -1) {
      partsUploaded[ip] = { ...part };
    } else {
      partsUploaded.push(part);
    }
    setPartsUploaded([...partsUploaded]);
  };

  const canNext = () => {
    if (project?.archived == true) {
      return false;
    }

    const i = !project.selfCalculation
      ? project.parts?.findIndex(
          (p) => (p.auto && !p.totalPrice) || (!p.auto && !p.material)
        )
      : project.parts?.findIndex((p) => p.totalPrice === null);
    const pUpload = Object.keys(uploadFiles)?.findIndex(
      (key) => uploadFiles[key] === null
    );
    const isFormValid = !!adaptForm.getFieldValue("deliveryDate");
    if (project.selfCalculation) {
      return isFormValid && i === -1 && pUpload === -1;
    } else return i === -1 && pUpload === -1;
  };

  useEffect(() => {
    if (canNext()) setError(undefined);
  }, [project]);

  const onSubmit = async () => {
    setError(undefined);
    if (!canNext()) {
      adaptForm.validateFields();
      setError(true);
      return;
    }
    if (project.order.adaptedDeliveryDate !== adaptDeliveryDate) {
      await onAdaptDeliveryDate(adaptForm.getFieldsValue());
    }
    if (onNext) onNext();
  };

  useEffect(() => {
    const data = files;
    setAdditionFiles(data);
    getCompliances();
    return () => {
      setAdditionFiles([]);
      if (onFilesLoaded) onFilesLoaded();
    };
  }, []);

  useEffect(() => {
    if (cadFiles.length > 0) {
      setAdditionFiles([...cadFiles, ...additionFiles]);
      if (onAddFiles) onAddFiles();
    }
  }, [cadFiles]);

  useEffect(() => {
    setIsShowCalculateAll(
      Object.keys(partsStatus).some(
        (p: any) => partsStatus[p] === PartViewMode.EDIT
      )
    );
  }, [partsStatus]);

  const onCadFilesChange = ({ files }: any) => {
    setErrorMax(false);
    onCloseUploadParts();
    const data = files;
    setCadFiles(data);
  };

  const onSubmitFormGenerateDxf = (rs: any) => {
    onCloseUploadParts();
    setAdditionFiles([rs, ...additionFiles]);
    dispatch(projectActions.setPart(rs));
  };

  const onOpeneUploadParts = () => {
    setIsOpepUploadParts(true);
  };

  const onCloseUploadParts = () => {
    setIsOpepUploadParts(false);
  };

  const onCuttingTypeUploadChange = (value: any) => {
    setCuttingTypeUpload(value);
  };

  const onAdaptDeliveryDate = async (values: any) => {
    setLoadingAdaptDeliveryDate(true);
    try {
      const rs = await projectServices.adaptDeliveryDate({
        projectId: project.id,
        deliveryDate: values.deliveryDate.format("YYYY-MM-DD"),
      });
      dispatch(projectActions.setProject(rs));
      api.info({
        message: `Adapt delivery date`,
        description: (
          <>
            Change delivery date to{" "}
            {moment(values.deliveryDate.format("YYYY-MM-DD")).format("L")}!
          </>
        ),
        placement: "topRight",
      });
    } catch (error) {
      api.error({
        message: `Adapt delivery date`,
        description: <>Failed!</>,
        placement: "topRight",
      });
    }
    setLoadingAdaptDeliveryDate(false);
  };

  const onAdaptDeliveryDateChange = (e: any) => {
    setAdaptDeliveryDate(e.format("YYYY-MM-DD"));
  };

  const onAdaptReset = () => {
    adaptForm.setFieldValue("deliveryDate", dayjs(project.order.deliveryDate));
    setAdaptDeliveryDate(project.order.deliveryDate);
  };

  const onCalculateAll = () => {
    dispatch(projectActions.callCalculateAll(true));
    setTimeout(() => {
      dispatch(projectActions.resetCalculateAll(true));
    }, 300);
  };

  const onOpenMoveCloneParts = (mode: any) => {
    setIsOpenMoveCloneParts(true);
  };

  const RenderCardFileItem = (part: Part) => {
    return (
      <CadFileItem
        key={`part-${part.id}`}
        type={part.cuttingType}
        part={part}
      />
    );
  };

  const RenderAdditionFiles = (item: any) => {
    if (item.partFileName?.length) {
      return RenderCardFileItem(item);
    }

    return (
      <CadFileItem
        key={`${item.cadFile?.uid}`}
        type={cuttingTypeUpload}
        file={item.cadFile}
        pdfFile={item.pdfFile}
        onPartUploadedChange={onPartUploadedChange}
        onPartRender={onPartRender}
      />
    );
  };

  const onPartRender = (value: any) => {
    if (value && sheetMetalTypes?.includes(value) && !isSheetMetalPartExsit) {
      setIsSheetMetalPartExsit(true);
    }
  };

  const checkSheetMetalPartShowNoti = (
    cuttingType: CuttingType | undefined
  ) => {
    if (!cuttingType) {
      return;
    }

    if (sheetMetalTypes?.includes(cuttingType)) {
      setIsSheetMetalPartExsit(true);
      return true;
    }

    return false;
  };

  useEffect(() => {
    let count = 0;
    setIsSheetMetalPartExsit(false);
    if (project.parts) {
      project.parts.map((part: Part) => {
        if (checkSheetMetalPartShowNoti(part.cuttingType)) {
          count++;
          return;
        }
      });
    }

    setIsSheetMetalPartExsit(count > 0);
  }, [project.parts]);

  return (
    <>
      {contextHolder}
      {isSheetMetalPartExsit && (
        <Space className="mb-2" direction="vertical" style={{ width: "100%" }}>
          <Alert message={t("part.drawingWarning")} type="info" />
        </Space>
      )}
      <DragDropZone onSelectedFiles={onCadFilesChange}>
        <Sticky top={0} bottomBoundary={0} innerZ={999} classNam="align-items-center">
          <Row className="calculation-tool-box align-items-center mb-3">
            <Col md={5} xs={24} className="">
              {!project?.archived && (
                <Button
                  className=""
                  type="primary"
                  onClick={onOpeneUploadParts}
                >
                  <PlusOutlined /> {t("addPart")}
                </Button>
              )}
            </Col>
            <Col md={19} xs={24} className="">
              <Row className="">
                <Col md={24} xs={24} className="d-flex" style={{justifyContent: "end"}}>
                  {project.selfCalculation && (
                    <Form
                      className="app-form delivery-date-inline"
                      form={adaptForm}
                      initialValues={{
                        projectId: project.id,
                        deliveryDate:
                          project.order?.adaptedDeliveryDate ||
                          project.order?.deliveryDate
                            ? dayjs(
                                project.order?.adaptedDeliveryDate ||
                                  project.order?.deliveryDate
                              )
                            : "",
                      }}
                      onFinish={onAdaptDeliveryDate}
                      disabled={loadingAdaptDeliveryDate}
                    >
                      <Form.Item name="projectId" hidden>
                        <Input />
                      </Form.Item>
                      <div className="" style={{height: "100%"}}>
                        {!!project.order?.deliveryDate && (
                          <Form.Item className="mb-0">
                            <Button
                              type="default"
                              icon={<UndoOutlined />}
                              disabled={
                                project.order.deliveryDate === adaptDeliveryDate
                              }
                              onClick={onAdaptReset}
                            />
                          </Form.Item>
                        )}
                        <Popover
                          placement="left"
                          content={
                            <>
                              <p className="mb-2 fw-bold">
                                {t("originalDeliveryDate")}
                              </p>
                              {project.order?.deliveryDate &&
                                moment(project.order.deliveryDate).format("L")}
                            </>
                          }
                          trigger="hover"
                          open={isOpenOriginalDeliveryDate}
                        >
                          <Form.Item
                            className="hidden-label d-flex"
                            label={t("project.deliveryDate")}
                            name="deliveryDate"
                            rules={[{ required: true }]}
                          >
                            <DatePicker
                              disabled={project?.archived}
                              style={{ width: "15vw", height: "100%" }}
                              allowClear={false}
                              onFocus={setIsOpenOriginalDeliveryDate.bind(
                                null,
                                !!project.order?.deliveryDate
                              )}
                              onBlur={setIsOpenOriginalDeliveryDate.bind(
                                null,
                                false
                              )}
                              onChange={onAdaptDeliveryDateChange}
                              disabledDate={disabledDate}
                            />
                          </Form.Item>
                        </Popover>
                      </div>
                    </Form>
                  )}
                  {isShowCalculateAll && (project?.parts?.length || 0) > 1 && (
                    <Button
                      className="ms-3"
                      type="primary"
                      onClick={onCalculateAll}
                      icon={<SettingOutlined/>}
                    >
                      {t("calculation.calculateAll")}
                    </Button>
                  )}
                  <Button
                    type="primary"
                    onClick={onSubmit}
                    className="ms-3"
                    disabled={!canNext()}
                  >
                    {t("calculation.submitToReview")} <ArrowRightOutlined />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Sticky>
        {errorMax && (
          <Alert
            className="mb-3"
            message={`Max part ${MAX_PART}`}
            type="error"
            showIcon
          />
        )}
        <div className="">
          {additionFiles.map((item, index) => {
            return RenderAdditionFiles(item);
          })}
          {parts?.map((item) => RenderCardFileItem(item))}
          {error && (
            <Alert
              message={t("project.error.goToReview")}
              type="error"
              showIcon
            />
          )}
        </div>
      </DragDropZone>

      <Modal
        open={isOpepUploadParts}
        width={"80vw"}
        footer={null}
        onCancel={onCloseUploadParts}
        destroyOnClose
      >
        <CalculationStep1Switcher
          onCuttingTypeChange={onCuttingTypeUploadChange}
          onChange={onCadFilesChange}
          onGenerateDxf={onSubmitFormGenerateDxf}
        />
      </Modal>
      {/* <Modal
        open={isOpenMoveCloneParts}
        width={"50vw"}
        footer={null}
        onCancel={setIsOpenMoveCloneParts.bind(null, false)}
      >
        <MoveAndCloneParts mode="MOVE" project={project} />
      </Modal> */}
    </>
  );
}

export default CalculationStep2;
