import { configSelector } from "app/redux/slides/config.slide";
import { useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

function AppBrowserRouter (props: any) {
    const config = useSelector(configSelector);
    return <BrowserRouter basename={config.subfolderFrontendUrl ? `/${config.subfolderFrontendUrl}` : '/'}>
    {props.children}
  </BrowserRouter>;
}
export default AppBrowserRouter;